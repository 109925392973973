.support_chat_root {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}

.support_chat_root p {
  margin: 0;
}

.support_chat_root button {
  cursor: pointer;
}

.support_chat_container {
  border-radius: 1rem;
  width: 18rem;
  background-color: #ffffff;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
}

.support_chat_inner_container {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.support_chat_header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  color: #ffffff;
  background-color: #000000;
  text-align: left;
}

.support_chat_header_main {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.support_chat_header_title {
  font-weight: 700;
}
.support_chat_header_action_group {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.support_chat_header_action {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.support_chat_header_action_primary_icon {
  width: 1rem;
  height: 1rem;
  fill: white;
}
.support_chat_header_action_secondary_icon {
  width: 1rem;
  height: 1rem;
  stroke: white;
}
.support_chat_header_subtitle {
  font-size: 0.75rem;
  line-height: 1rem;
}
.support_chat_body_container {
  overflow-y: auto;
  position: relative;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex: 1 1 0%;
}

.support_chat_bubble_me {
  display: flex;
  margin-bottom: 0.75rem;
  justify-content: flex-end;
}
.support_chat_bubble_me_text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-right: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  height: fit-content;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffffff;
  background-color: black;
  overflow-wrap: anywhere;
  text-align: left;
}
.support_chat_bubble_other {
  display: flex;
  margin-bottom: 0.75rem;
}
.support_chat_bubble_other_text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  height: fit-content;
  font-size: 0.75rem;
  line-height: 1rem;
  color: black;
  border: 1px solid black;
  overflow-wrap: anywhere;
  text-align: left;
}
.support_chat_bubble_avatar_icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: black;
}

.support_chat_input_container {
  display: flex;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.support_chat_input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex: 1 1 0%;
  font-size: 0.75rem;
  line-height: 1rem;
  border: none;
  outline: none;
}
.support_chat_input_button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.send_icon {
  width: 1rem;
  height: 1rem;
  fill: black;
}
.support_chat_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background-color: #F5F5F5;
  width: 2.5rem;
  height: 2.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  border: none;
  cursor: pointer;
  padding: 0;
}

.close_icon {
  width: 1rem;
  height: 1rem;
  fill: black;
}
.chat_bubble_icon {
  width: 1rem;
  height: 1rem;
  fill: black;
}

.loading_reply_container {
  display: flex;
  margin-bottom: 0.75rem;
}
.loading_reply_body {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: 0.5rem;
  gap: 0.25rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  height: fit-content;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #000000;
  background-color: lightgray;
}

.loading_reply_item {
  display: flex;
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
}

.loading_reply_item_upper {
  display: inline-flex;
  position: absolute;
  border-radius: 9999px;
  width: 100%;
  height: 100%;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  opacity: 0.75;
  background-color: #000000;
}

.loading_reply_item_lower {
  display: inline-flex;
  position: relative;
  border-radius: 9999px;
  width: 0.5rem;
  height: 0.5rem;
}
@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.chat_reply_options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.chat_reply_options_input_type {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.75rem;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.chat_reply_options_input_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.chat_reply_options_input_label {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}
.chat_reply_options_input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  border-width: 1px;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
}
.chat_reply_options_send_button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ffffff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  background-color: black;
  border: none;
}
.chat_reply_options_send_button :hover {
  opacity: 0.9;
}

.chat_reply_options_send_button.disabled {
  background-color: lightgray;
  color: lightslategray;
  cursor: auto;
}
.chat_reply_options_button_type {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 1.5rem;
  border-width: 1px;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
}

.end_chat_modal_container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 3rem;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: #ffffff;
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}
.end_chat_modal_title {
  font-weight: 700;
}
.end_chat_modal_action_group {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}
.end_chat_modal_action_secondary {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  flex: 1 1 0%;
  border-width: 1px;
  border-color: #000000;
  text-align: center;
  cursor: pointer;
}
.end_chat_modal_action_primary {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  flex: 1 1 0%;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  cursor: pointer;
}

@media (min-width: 768px) {
  .support_chat_root {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
  .support_chat_container {
    width: 20rem;
  }
  .support_chat_button {
    width: 3.5rem;
    height: 3.5rem;
  }
  .close_icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .chat_bubble_icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .support_chat_inner_container {
    height: 530px;
  }
}
