/* mobile view */
.itemsMainContainer {
  padding-top: 100px;
}

.itemsHeaderContainer {
  padding: 3.5em 1em 0.5em 1em;
}

.itemsHeaderTitleAndResult {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.itemsHeaderTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #006787;
}

.itemsHeaderResult {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.eachItemChild div {
  font-style: normal;
  font-weight: 400;
  font-size: 9.5px;
  margin: 0.25em 1em 0.25em 1em;
}

.itemsHeaderItemChilds {
  padding: 0.5em 0.2em 0.5em 0.2em;
  gap: 0.5em;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.itemHeaderAndSideFilterContainer {
  position: sticky !important;
  top: 9.5vh !important;
  z-index: 10;
  background-color: #ffffff;
}

.eachItemChild {
  display: flex;
  border-radius: 20px;
  cursor: pointer;
}

.itemsHeaderSlider {
  padding: 2vh 10vw 2vh 10vw;
}

.leftFilterBox {
  margin-bottom: 1em;
  width: 100%;
  text-align: left;
  height: 100%;
}

.itemsSidebarAndContentContainer {
  padding: 1em 1em 1em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemContentContainer {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
}

.eachItemContentContainer {
  margin: 2vh 1vw 2vh 1vw;
  cursor: pointer;
}

.eachItemContentContainer img {
  display: block;
  border-radius: 24px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.eachItemContentImgContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.eachItemContentImgContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.eachItemContentImgContainer:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.leftFilterBoxTitleLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
}

.leftFilterBoxOptions {
  margin-top: 0.5em;
}

.leftFilterBoxEachOption {
  margin: 0.25em 0.25em 0.25em 0.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftFilterCheckboxContainer {
  margin: 0.5em 0.25em 0.5em 0.25em;
}

.leftFilterBoxOptionTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.leftFilterBoxUnselectAndArrowIconContainer {
  display: flex;
  align-items: center;
}

.leftFilterDropdownIcon {
  width: 10px;
  height: auto;
}

.leftFilterBoxUnselectAndArrowIconContainer div {
  cursor: pointer;
  margin-right: 0.75em;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-decoration-line: underline;
  color: #9e9e9e;
}

.leftFilterBoxUnselectAndArrowIconContainer img {
  cursor: pointer;
}

.eachItem {
  width: 100%;
  margin-bottom: 2em;
}

.eachItem img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  cursor: pointer;
}

.eachItemDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.divInputCheckbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  align-items: center;
}

.divInputCheckbox input {
  width: 20px;
  height: 20px;
  align-self: center;
}

.divInputCheckbox div {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-left: 0.5em;
}

.mobileDivInputCheckbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  margin-top: 2em;
}

.mobileDivInputCheckbox input {
  width: 20px;
  height: 20px;
  align-self: center;
}

.itemPaginationContainer {
  margin-bottom: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-style: normal;
  color: #525252;
  gap: 1em;
  list-style: none;
}

.paginationLabel {
  font-size: 16px;
}

.paginationBtnStyle {
  width: 10px;
  height: auto;
}

.itemPriceContainer {
  display: flex;
  gap: 0.25vw;
}

.salePriceStyle {
  color: rgb(255, 94, 0);
}

.percentageSaleStyle {
  color: rgb(255, 94, 0);
}

.slick-prev:before {
  content: "<";
  font-size: 20px;
  display: none;
}

.slick-next:before {
  content: ">";
  font-size: 20px;
}

.slick-prev:before,
.slick-next:before {
  color: #000000;
}

.emptyProductContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}

.emptyProductContainer img {
  width: 20vw;
  height: auto;
}

.emptyProductContainer div {
  text-align: center;
  margin-top: 1em;
  width: 100%;
  color: #525252;
  text-align: center;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
}

/* desktop view */
@media only screen and (min-width: 640px) {
  .itemsMainContainer {
    margin-top: 15vh;
  }

  .itemsHeaderContainer {
    padding: 2vh 5vw 0 5vw;
  }

  .itemsHeaderTitleAndResult {
    display: flex;
    justify-content: space-between;
  }

  .itemsHeaderTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 3.1vw;
    color: #006787;
  }

  .itemsHeaderResult {
    font-style: normal;
    font-weight: 400;
    font-size: 1.075vw;
    right: 0px;
    bottom: 0px;
  }

  .eachItemChild div {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    margin: 0.45vh 2.5vw 0.45vh 2.5vw;
  }

  .itemsHeaderItemChilds {
    padding: 0.5em 1em 0.5em 1em;
    flex: 1;
    display: flex;
  }

  .itemHeaderAndSideFilterContainer {
    position: sticky !important;
    top: 17.5vh !important;
    z-index: 10;
    background-color: #ffffff;
  }

  .eachItemChild {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
  }

  .itemsHeaderSlider {
    padding: 80px 5vw 2vh 5vw;
  }

  .leftFilterBox {
    padding-top: 80px;
    margin-bottom: 8vh;
    width: 25%;
    text-align: left;
    position: sticky;
    height: 100%;
    top: 31.2vh;
  }

  .itemsSidebarAndContentContainer {
    padding: 5vh 5vw 5vh 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .itemContentContainer {
    width: 60vw;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2vw;
  }

  .eachItemContentContainer {
    margin: 2vh 1vw 2vh 1vw;
    width: 16.5vw;
    cursor: pointer;
  }

  .eachItemContentContainer img {
    display: block;
    width: 100%;
    height: auto;
  }

  .eachItemContentImgContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 30vh;
  }

  .eachItemContentImgContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
  }

  .eachItemContentImgContainer:hover::before {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .eachItemContentImgContainer img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .sortByDropdownMainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 7.5vh;
    margin-right: 1.6vw;
    z-index: 1;
  }

  .sortByDropdownContainer {
    position: absolute;
    z-index: 1;
  }

  .leftFilterBoxTitleLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 1.79vw;
  }

  .leftFilterBoxOptions {
    margin-top: 2.5vh;
  }

  .leftFilterBoxEachOption {
    margin: 0.75vh 0.75vw 0.75vh 0.75vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .leftFilterBoxOptionTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    cursor: pointer;
  }

  .leftFilterBoxUnselectAndArrowIconContainer {
    display: flex;
    align-items: center;
  }

  .leftFilterBoxUnselectAndArrowIconContainer div {
    cursor: pointer;
    margin-right: 0.75vw;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    text-decoration-line: underline;
    color: #9e9e9e;
  }

  .leftFilterBoxUnselectAndArrowIconContainer img {
    cursor: pointer;
  }

  .eachItem {
    width: 30%;
    margin-bottom: 1.5em;
    height: 200px;
  }

  .eachItem img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 25px;
    cursor: pointer;
  }

  .eachItemDesc {
    text-align: left;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-size: 1vw;
  }

  .divInputCheckbox {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.6vh;
    align-items: flex-start;
  }

  .divInputCheckbox input {
    width: 2vw;
    height: 2vh;
    align-self: center;
  }

  .divInputCheckbox div {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    color: #000000;
    align-self: center;
    margin-left: 0.15vw;
  }

  .itemPaginationContainer {
    margin-bottom: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-style: normal;
    color: #525252;
    gap: 1.5vw;
    list-style: none;
  }

  .leftFilterDropdownIcon {
    width: 1vw;
    height: auto;
  }

  .leftFilterCheckboxContainer {
    margin-top: 1.75vh;
    margin-bottom: 3vh;
  }

  .paginationBtnStyle {
    width: 0.5vw;
    height: auto;
  }

  .paginationLabel {
    font-size: 1.05vw;
  }

  .emptyProductContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
  }

  .emptyProductContainer img {
    width: 8vw;
    height: auto;
  }

  .emptyProductContainer div {
    text-align: center;
    margin-top: 1em;
    width: 100%;
    color: #525252;
    text-align: center;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
  }
}
