.notificationMainContainer {
  position: fixed;
  right: 0px;
  left: 0px;
  z-index: 12;
  top: 66px;
}

.notificationContentContainer {
  padding: 4px 8px;
  background: #d9d9d9;
}

.notificationContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
}

.notificationContainer img {
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
}

@media only screen and (min-width: 640px) {
  .notificationMainContainer {
    right: 16px;
    left: unset;
    top: 170px;
  }
  .notificationContentContainer {
    padding: 8px 12px;
    border-radius: 5px;
  }

  .notificationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .notificationContainer img {
    width: 20px;
    height: 20px;
    margin-right: 0.5em;
  }
}
