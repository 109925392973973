/* mobile view */
.landingPageContainer {
  margin-top: 4.4em;
  margin-bottom: 4.4em;
}

.landingPageImage1 img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.landingPageBodyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 2.5em 3em 2.5em;
}

.landingPage3RowImagesEachImage img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.landingPage3RowImagesEachImageLabel {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  margin-top: 0.15em;
}

.landingPageImage2 {
  margin-top: 3.5em;
}

.slide-container {
  position: relative;
  width: 100%;
  height: 500px;
}

.image-cover {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.image-cover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  text-align: center;
}

.contentLabel1 {
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.contentLabel2 {
  margin-top: 1em;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 275px;
}

.contentButtonExplore {
  margin-top: 1.75em;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 35px;
  background-color: #006787;
  border-radius: 20px;
}

.landingPage2RowImages {
  width: 100%;
  margin-top: -8em;
  display: flex;
  flex-direction: column;
}

.slide-container-row-2-images {
  position: relative;
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.content-row-2-images {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  width: 200px;
}

.landingPageFeaturedProductsContainer {
  margin-top: 1.5em;
}

.landingPageFeaturedProductTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 7.5vw;
  color: #006787;
  margin-bottom: 3vh;
}

.landingPageFeaturedProducts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.landingPageFeaturedPordutsScrollerContainer {
  margin-left: 5vw;
  margin-right: 5vw;
  width: 90vw;
}

.landingPageFeaturedContentEach::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.landingPageFeaturedContentEach:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.landingPageFeaturedContentEach img {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.landingPageFeaturedEach {
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 100%;
}

/* .landingPageFeaturedEach img {
  width: 100%;
  height: auto;
  object-fit: cover;
} */

.landingPageFeaturedEachDesc {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.landingPageFeaturedProductViewAllBtn {
  margin-top: 0.5rem;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.landingPageFeaturedProductViewAllBtnLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-right: 12px;
}

.landingPageFeaturedProductViewAllBtn img {
  width: 6px;
  height: auto;
}

/* desktop view */
@media only screen and (min-width: 640px) {
  .landingPageContainer {
    margin-top: 18vh;
    margin-bottom: 5vh;
  }

  .landingPageImage1 img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
  }

  .landingPageBodyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vh 5vw 3vh 5vw;
  }

  .landingPage3RowImages {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 3vw;
  }

  .landingPage3RowImagesEachImage img {
    width: 27.5vw;
    height: 50vh;
    object-fit: cover;
  }

  .landingPage3RowImagesEachImageLabel {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    margin-top: 0.5vh;
  }

  .landingPageImage2 {
    margin-top: 5vh;
  }

  .slide-container {
    position: relative;
    width: 100%;
    height: 55vh;
  }

  .image-cover {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image-cover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    top: 27.5vh;
    left: 27.5vw;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    text-align: center;
    width: 45vw;
  }

  .contentLabel1 {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
  }

  .contentLabel2 {
    margin-top: 1.5em;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    width: 45vw;
  }

  .contentButtonExplore {
    margin-top: 4vh;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 5vh;
    background-color: #006787;
    border-radius: 20px;
    font-size: 1.25vw;
  }

  .landingPage2RowImages {
    margin-top: 3.5vh;
    display: flex;
    flex-direction: row;
    gap: 5vw;
  }

  .slide-container-row-2-images {
    position: relative;
    width: 50vw;
    height: 62.5vh;
  }

  .content-row-2-images {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 3vw;
    line-height: 7.5vh;
    width: 50%;
  }

  .landingPageFeaturedProductsContainer {
    margin-top: 7.5vh;
  }

  .landingPageFeaturedProductTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 3vw;
    color: #006787;
    margin-bottom: 1.5vh;
  }

  .landingPageFeaturedEach {
    width: 25vw;
  }

  /* .landingPageFeaturedEach img {
    display: block;
    width: 100%;
    height: 100%;
  } */

  .landingPageFeaturedContentEach {
    position: relative;
    display: inline-block;
    width: 25vw;
    height: 40vh;
  }

  .landingPageFeaturedProducts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .landingPageFeaturedPordutsScrollerContainer {
    margin-left: 5vw;
    margin-right: 5vw;
    width: 88.5vw;
  }

  .landingPageFeaturedContentEach::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
  }

  .landingPageFeaturedContentEach:hover::before {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .landingPageFeaturedContentEach img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .landingPageFeaturedEachDesc {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  .landingPageFeaturedProductViewAllBtn {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.5em;
    margin-right: 5vw;
    cursor: pointer;
  }

  .landingPageFeaturedProductViewAllBtnLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding-right: 10px;
    margin-top: -0.15em;
  }

  .landingPageFeaturedProductViewAllBtn img {
    width: 9.5px;
    height: auto;
  }
}
