.shoppingCartMainContainer {
  padding-top: 2em;
  text-align: left;
  width: 100%;
}

.shoppingCartContainer {
  margin: 2em 1em 2em 1em;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shoppingCartAndContactInformationAndShippingAddressContainer {
  width: 100%;
  margin-top: 1em;
}

.shoppingCartMenu {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #006787;
}

.emptyShoppingCartContainer {
  margin-top: 4em;
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.emptyShoppingCartContainer img {
  width: 30%;
  height: auto;
}

.emptyShoppingCartLabel {
  margin-top: 1em;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.emptyShoppingCartShopNowLabel {
  margin-top: 0.5em;
  color: #006787;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}

.headerShoppingCarts {
  width: 100%;
  margin-top: 2em;
  margin-left: 0em;
  margin-right: 2em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 12em */
}

.headerShoppingCartsLabel {
  margin-top: -0.3em;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #525252;
  width: 20%;
  padding-left: 0em;
  text-align: center;
}

.headerUnitShopLabel {
  margin-top: -0.3em;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #525252;
  width: 25%;
}

.cartsContainer {
  padding-top: 1.5em;
  padding-bottom: 0em;
}

.eachCartContainer {
  display: flex;
  margin-bottom: 1.5em;
  margin-left: 0;
  align-items: center;
  text-align: left;
}

.cartImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 4vw;
  margin-right: 4vw;
}

.cartImageContainer img {
  object-fit: cover;
  width: 25vw;
  height: 12.5vh;
  border-radius: 6px;
}

.cartQtyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 2em;
}

.cartDescriptionQtyOptionsContainer {
  border-radius: 5px;
}

.itemDetailDescriptionQtyOptionsMobile {
  margin: 0.01em 0.3em 0.01em 0.3em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.itemDetailDescriptionQtyBtnMobile {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.itemDetailDescriptionQtyValueMobile {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.cartVariantContainer {
  margin-top: 0.05em;
}

.eachCartVariantContainer {
  margin-top: 0.125em;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #9e9e9e;
  text-align: left;
  margin-left: 0;
}

.cartUnitPriceContainer {
  text-align: left;
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  margin-top: 0.3em;
  padding-right: 10%;
}

.cartProductName {
  font-size: 13.5px;
  font-weight: 500;
}

.cartVariantProduct {
  text-align: left;
  font-size: 12px;
  font-weight: 300;
}

.cartTotalPriceContainer {
  margin-left: 1em;
  text-align: center;
  flex: 1;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #000000;
}

.cartTotalPriceContainer img {
  width: 17px;
  height: auto;
  cursor: pointer;
}

.askPromoCodeContainer {
  margin-top: 3em;
  display: flex;
  flex-wrap: wrap;
  gap: 3vw;
}

.askPromoCodeLabelContainer {
  font-size: 4.5vw;
}

.applyPromoCodeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  height: 5.75vh;
  border-radius: 5px;
  font-size: 4vw;
}

.inputContainer {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
}

.inputLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0.5em;
}

.inputPhoneContainer {
  margin-top: 1.5em;
}

.inputPhoneBox {
  display: flex;
  gap: 1.5em;
}

.inputPhoneCode {
  width: 45px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9e9e9e;
  background-color: #f5f5f5;
  padding-right: 5px;
  padding-left: 5px;
}

.useSameInfoContainer {
  display: flex;
  align-items: center;
  margin-top: 0.75em;
  gap: 0.5em;
}

.useSameInfoLabel {
  margin-top: -0.3em;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.btnContinueToPaymentContainer {
  margin: 2em 1em 2em 1em;
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.btnContinueToPayment {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  height: 32px;
  background-color: #000000;
  cursor: pointer;
  margin: 0px 16px;
}

.shoppingCartAndOrderSummaryContainer {
  display: flex;
  justify-content: space-between;
}

.orderSummaryMainContainer {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: sticky;
  height: 100%;
  top: 25.1vh;
  justify-content: space-between;
}

.orderSummaryContainer {
  margin: 1em 1em 1em 1em;
}

.orderSummaryTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #006787;
  margin-bottom: 1em;
}

.orderContainer {
  margin-top: 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderDescLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.orderDescValue {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.orderSummaryTotalContainer {
  margin: 2em 1em 1.5em 1em;
}

.totalItemsOrderedContainer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.dividerOrderSummary {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: 1px solid #525252;
}

.totalOrderDescLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.includeSaleTax {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #9e9e9e;
}

.validIconContainer {
  display: flex;
  align-items: center;
  margin-left: -3.61em;
  margin-right: 1em;
}

.validIconContainer img {
  width: 18px;
  height: 18px;
}

.couponStyle {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-top: 0.5em;
}

.deleteAllCartItemContainer {
  margin-top: 1.5em;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.deleteAllCartIconAndLabelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.deleteAllCartIconAndLabelContainer img {
  margin-right: 0.5em;
  width: 15px;
  height: auto;
}

.completeYourPurchaseLabel {
  margin-top: 0.5em;
  font-size: 14px;
  font-weight: 500;
  flex-wrap: wrap;
}

.eachSubBabContainer {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.eachSubBabTitle {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0.25em;
}

.eachBoxContainerShippingMethodEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16ch;
}

.eachBoxOptionContainer {
  margin-top: 1em;
  border-radius: 10px;
  background: #f5f5f5;
}

.eachBoxContainer {
  padding: 1em 1.5em 1em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eachBoxContainerShippingMethod {
  padding: 1em 1.5em 1em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.checkboxAndLabelContainer {
  display: flex;
  align-items: center;
}

.eachOptionName {
  font-size: 14px;
  font-weight: 700;
}

.eachOptionEstimateTime {
  font-size: 13px;
}

.eachShippingOptContainer {
  display: flex;
  gap: 2em;
  margin-top: 0.5em;
  align-items: center;
  justify-content: space-between;
}

.checkboxAndLabelContainer img {
  margin-left: 0.5em;
}

.shoppingCartSuccessPaymentMainContainer {
  margin: 5rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.successPaymentIconContainer {
  background: #f9f9f9;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.successPaymentIconContainer img {
  width: 70px;
  height: 70px;
}

.successPaymentBoxContainer {
  margin-top: -2.75em;
  width: 100%;
  background: #f9f9f9;
  border-radius: 10px;
}

.successPaymentContentContainer {
  margin-top: 3.5em;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.thankyouForYourOrderStyle {
  color: #006787;
  font-size: 18px;
  font-weight: 700;
}

.thankyouForYourOrderDescStyle {
  margin-top: 0.5em;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.orderIdContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orderIdLabel {
  color: #525252;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.orderIdBox {
  margin-top: 0.25em;
  border-radius: 10px;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderIdBox div {
  margin: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 500;
}

.thankyouForYourOrderDivider {
  display: flex;
  justify-content: center;
}

.thankyouForYourOrderDivider div {
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: 0.9px solid #9e9e9e;
}

.orderDescContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.orderDescContentContainer {
  width: 100%;
}

.eachOrderDescContainer {
  margin-bottom: 0.35em;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.pleaseScreenshotStyle {
  margin-top: 2em;
  color: #9e9e9e;
  font-size: 12px;
}

.buttonMainContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btnPrintContainer {
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  cursor: pointer;
  font-size: 14px;
}

.btnPrintContainer img {
  width: 20px;
  height: auto;
  margin-right: 0.75em;
}

.btnContinueShopping {
  cursor: pointer;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 14px;
}

@media only screen and (min-width: 640px) {
  .shoppingCartMainContainer {
    padding-top: 18vh;
    text-align: left;
    width: 100%;
  }

  .shoppingCartContainer {
    margin: 80px 5vw 3em 5vw;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .shoppingCartAndContactInformationAndShippingAddressContainer {
    width: 100%;
    margin-top: 0em;
  }

  .shoppingCartMenu {
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;
    color: #006787;
  }

  .emptyShoppingCartContainer {
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .emptyShoppingCartContainer img {
    width: 9vw;
    height: auto;
  }

  .emptyShoppingCartLabel {
    margin-top: 2vh;
    color: #000;
    text-align: center;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
  }

  .emptyShoppingCartShopNowLabel {
    margin-top: 2vh;
    color: #006787;
    text-align: center;
    font-size: 1.1vw;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .headerShoppingCarts {
    width: 100%;
    margin-top: 1vw;
    margin-left: 1vw;
    margin-right: 2vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .headerShoppingCartsLabel {
    margin-top: -0.75vh;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95vw;
    color: #525252;
    width: 25%;
    padding-left: 4vw;
    text-align: left;
  }

  .headerQtyShoppingCartsLabel {
    margin-top: -0.75vh;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95vw;
    color: #525252;
    width: 25%;
    padding-left: 5vw;
    text-align: center;
  }

  .headerUnitShopLabel {
    margin-top: -0.75vh;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95vw;
    color: #525252;
    width: 25%;
  }

  .cartsContainer {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }

  .eachCartContainer {
    display: flex;
    margin-bottom: 4vh;
    margin-left: 1vw;
    align-items: center;
    text-align: center;
  }

  .cartImageContainer {
    margin-left: 3vw;
    margin-right: 0;
    display: flex;
    font-size: 0.95vw;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .cartImageContainer img {
    object-fit: cover;
    width: 15vw;
    height: 25vh;
    border-radius: 11.2202px;
  }

  .cartProductName {
    text-align: left;
    margin-top: 0.3em;
    font-size: 0.9vw;
    font-weight: 700;
    width: 100%;
  }

  .cartVariantProduct {
    text-align: left;
    width: 100%;
  }

  .cartQtyContainer {
    margin-left: 3vw;
    width: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    margin-top: 0;
  }

  .cartDescriptionQtyOptionsContainer {
    border: 1px solid #000000;
    border-radius: 5px;
  }

  .cartVariantContainer {
    margin-top: 0.75em;
  }

  .eachCartVariantContainer {
    margin-top: 0.125em;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #9e9e9e;
    text-align: center;
    margin-left: 0;
  }

  .cartUnitPriceContainer {
    margin-top: 0;
    text-align: left;
    width: 20%;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    color: #000000;
    margin-left: 3vw;
  }

  .cartTotalPriceContainer {
    margin-left: 0;
    text-align: center;
    flex: 1;
    width: 25%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 1vw;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    color: #000000;
    margin-left: 0;
  }

  .cartTotalPriceContainer img {
    width: 1.1vw;
    height: auto;
    cursor: pointer;
  }

  .askPromoCodeContainer {
    margin-top: 1vh;
    margin-bottom: 7.5vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.8vw;
  }

  .applyPromoCodeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6vw;
    height: 5.75vh;
    border-radius: 5px;
  }

  .applyPromoCodeBtn div {
    font-size: 1vw;
  }

  .askPromoCodeLabelContainer {
    font-size: 1.15vw;
  }

  .inputContainer {
    margin-top: 2.9vh;
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-wrap: nowrap;
    width: 100%;
  }

  .inputLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25vw;
    margin-bottom: 0.75vh;
  }

  .inputPhoneContainer {
    margin-top: 3vh;
    width: 100%;
  }

  .inputPhoneBox {
    display: flex;
    gap: 1.5vw;
  }

  .inputPhoneCode {
    width: 3.25vw;
    height: 5.85vh;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 0.75vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9e9e9e;
    background-color: #f5f5f5;
    padding-right: 5px;
    padding-left: 5px;
  }

  .useSameInfoContainer {
    display: flex;
    align-items: center;
    margin-top: 1vh;
    gap: 0.05vw;
  }

  .useSameInfoLabel {
    margin-top: -0.5vh;
    font-style: normal;
    font-weight: 400;
    font-size: 0.95vw;
  }

  .btnContinueToPaymentContainer {
    margin: 6vh 5vw 6vh 5vw;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  .btnContinueToPayment {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    width: 25%;
    height: 40px;
    background-color: #000000;
    cursor: pointer;
    margin: 0;
  }

  .shoppingCartAndOrderSummaryContainer {
    display: flex;
    justify-content: space-between;
  }

  .orderSummaryMainContainer {
    width: 25%;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: sticky;
    height: 100%;
    top: 25.1vh;
    justify-content: space-between;
  }

  .orderSummaryContainer {
    margin: 1.5vw 3vh 1.5vw 3vh;
  }

  .orderSummaryTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;
    color: #006787;
    margin-bottom: 1.5vh;
  }

  .orderContainer {
    margin-top: 0.75em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .orderDescLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 1.075vw;
  }

  .orderDescValue {
    font-style: normal;
    font-weight: 400;
    font-size: 1.075vw;
  }

  .orderSummaryTotalContainer {
    margin: 8vh 1.5vw 3vh 1.5vw;
  }

  .totalItemsOrderedContainer {
    display: flex;
    justify-content: space-between;
    font-size: 1.075vw;
  }

  .dividerOrderSummary {
    margin-top: 1.85vh;
    margin-bottom: 1.85vh;
    border: 1px solid #525252;
  }

  .totalOrderDescLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    letter-spacing: 0.02em;
  }

  .includeSaleTax {
    font-style: normal;
    font-weight: 400;
    font-size: 0.95vw;
    color: #9e9e9e;
  }

  .validIconContainer {
    display: flex;
    align-items: center;
    margin-left: -3.61vw;
    margin-right: 1vw;
  }

  .validIconContainer img {
    width: 1.1vw;
    height: auto;
  }

  .couponStyle {
    font-size: 0.65vw;
    font-style: normal;
    font-weight: 400;
    padding-top: 1vh;
  }

  .deleteAllCartItemContainer {
    margin-top: 2.9vh;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .deleteAllCartIconAndLabelContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.95vw;
  }

  .deleteAllCartIconAndLabelContainer img {
    margin-right: 0.5em;
    width: 1vw;
    height: auto;
  }

  .completeYourPurchaseLabel {
    margin-top: 1em;
    font-size: 1vw;
    font-weight: 500;
  }

  .eachSubBabContainer {
    width: 62.5vw;
    margin-top: 6vh;
    margin-bottom: 6vh;
  }

  .eachSubBabTitle {
    font-size: 1.5vw;
    font-weight: 700;
    padding-bottom: 0.25vh;
  }

  .eachBoxOptionContainer {
    margin-top: 2.9vh;
    border-radius: 10px;
    background: #f5f5f5;
  }

  .eachBoxContainer {
    padding: 2.5vh 2vw 2.5vh 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .eachBoxContainerShippingMethod {
    padding: 2.5vh 2vw 2.5vh 2vw;
  }

  .checkboxAndLabelContainer {
    display: flex;
    align-items: center;
  }

  .eachOptionName {
    font-size: 1vw;
    font-weight: 700;
  }

  .eachOptionEstimateTime {
    font-size: 1vw;
  }

  .eachShippingOptContainer {
    display: flex;
    gap: 5vw;
    margin-top: 1vh;
    align-items: center;
    justify-content: space-between;
  }

  .checkboxAndLabelContainer img {
    margin-left: 1vw;
  }

  .shoppingCartSuccessPaymentMainContainer {
    margin: 11rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .successPaymentIconContainer {
    background: #f9f9f9;
    border-radius: 130px;
    width: 116px;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .successPaymentIconContainer img {
    width: 96px;
    height: 96px;
  }

  .successPaymentBoxContainer {
    margin-top: -5em;
    width: 55%;
    background: #f9f9f9;
    border-radius: 10px;
  }

  .successPaymentContentContainer {
    margin-top: 6em;
    margin-bottom: 1em;
    margin-left: 0em;
    margin-right: 0em;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  .thankyouForYourOrderStyle {
    color: #006787;
    font-size: 24px;
    font-weight: 700;
  }

  .thankyouForYourOrderDescStyle {
    margin-top: 0.5em;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  .orderIdContainer {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .orderIdLabel {
    color: #525252;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.8px;
  }

  .orderIdBox {
    margin-top: 0.25em;
    width: 20%;
    border-radius: 10px;
    background: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .orderIdBox div {
    margin: 0.25em 1.5em 0.25em 1.5em;
    font-size: 22px;
    font-weight: 500;
  }

  .thankyouForYourOrderDivider {
    display: flex;
    justify-content: center;
  }

  .thankyouForYourOrderDivider div {
    width: 40%;
    margin-top: 1em;
    margin-bottom: 1em;
    border: 0.9px solid #9e9e9e;
  }

  .orderDescContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .orderDescContentContainer {
    width: 50%;
  }

  .eachOrderDescContainer {
    margin-bottom: 0.35em;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  .pleaseScreenshotStyle {
    margin-top: 2em;
    color: #9e9e9e;
    font-size: 14px;
  }

  .buttonMainContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btnPrintContainer {
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 45px;
    cursor: pointer;
    font-size: 16px;
  }

  .btnPrintContainer img {
    width: 26px;
    height: 25px;
    margin-right: 0.75em;
  }

  .btnContinueShopping {
    cursor: pointer;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: unset;
    height: 45px;
    font-size: 16px;
  }
}
