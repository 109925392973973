@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.navbarContainer {
  background-color: #ffffff;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid #d9d9d9;
}

.languageSection {
  padding-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5vw;
}

.arrowContainer {
  margin-top: -0.4em;
}

.languageSection img {
  width: 8px;
  height: auto;
}

.currencyDropdown {
  padding-right: 5px;
  font-size: 10px;
}

.promoSection {
  background-color: #19b0df;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promoValue {
  padding-top: 0.1vh;
  padding-bottom: 0.1vh;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 21px;
  color: #ffffff;
  display: block;
  width: 100%;
  transform: translateX(100%);
  animation: move 20s linear infinite;
}

.navbarSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logoSection {
  padding-top: 0.5em;
  margin-left: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.otherSections {
  margin-right: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.otherSectionsImgContainer {
  display: flex;
  position: relative;
}

.totalUsersCartContainer {
  background-color: #fa3e3e;
  border-radius: 50%;
  color: #ffffff;
  min-width: 14px;
  min-height: 14px;
  font-size: 8px;
  position: absolute;
  padding: 2px;
  top: -8px;
  right: -8px;
}

.swingClubLogoNavbar {
  margin-top: -1vh;
  width: 3.5vw;
  height: auto;
}

.swingClubLabelNavbar {
  margin-left: 1vw;
  width: 20vw;
  height: auto;
}

.searchIcon {
  width: 15px;
  height: auto;
}

.burgerBtnIcon {
  padding-left: 5px;
  margin-left: 1.2em;
  width: 27.5px;
  height: auto;
}

.cartIcon {
  margin-left: 1.2em;
  width: 18px;
  height: auto;
  cursor: pointer;
}

.accountIcon {
  margin-left: 1.2em;
  width: 13px;
  height: auto;
  cursor: pointer;
}

.navbarItemsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbarItem {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  width: auto;
  height: 100%;
  text-align: center;
  color: #000000;
  margin-right: 2em;
  margin-left: 2em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subMenuNavbarContainer {
  border-bottom: 1px solid #d9d9d9;
}

.subMenuContentContainer {
  display: flex;
  text-align: left;
  /* padding: 2.5em 5.2em 2.5em 5.2em; */
  gap: 1em;
}

.eachSubMenuContainer {
  width: 25%;
}

.eachSubMenuContainer img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.eachSubMenuContainer div {
  margin-top: 0.5em;
  cursor: pointer;
}

.navbarSearchInputMainContainer {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 95vh;
}

.searchOrderContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.navbarSearchIcon {
  position: fixed;
  left: 8px;
  width: 10px;
  height: auto;
}

.navbarSearchInputClearAndCloseBtn {
  position: fixed;
  right: 8vw;
  z-index: 100;
  display: flex;
  align-items: center;
  margin-left: -4em;
  gap: 2vw;
}

.navbarSearchInputClearAndCloseBtn .closeBtn img {
  width: 2.5vw;
  height: auto;
}

.clearBtnStyle {
  margin-right: 5px;
  font-size: 2.75vw;
  cursor: pointer;
}

.navbarSearchInputContainer {
  display: flex;
  position: sticky !important;
  top: 0vh !important;
  z-index: 10;
}

.navbarSearchValueMainContainer {
  width: 90vw;
  position: sticky !important;
  top: 6vh !important;
  height: 88vh;
  overflow: auto;
  border-radius: 10px;
}

.navbarSearchValueContainer {
  background-color: #ffffff;
}

.emptyNavbarSearchValueMainContainer {
  width: 90vw;
  position: sticky !important;
  top: 6vh !important;
  height: 88vh;
  overflow: auto;
}

.emptyNavbarSearchValueContainer {
  background-color: #ffffff;
  border-radius: 10px;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #006787;
}

.navbarSearchValueProductMainContainer {
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 3vw;
}

.navbarSearchValueContentContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50vw, 50vw));
  gap: 3vw;
}

.eachSearchValueContainer img {
  display: block;
  width: 100%;
  height: auto;
}

.eachSearchValueContainer {
  margin: 2vh 2vw 2vh 1vw;
}

.navbarSearchValueLabel {
  padding: 1vh 2vw 0vh 1vw;
  font-size: 3.5vw;
  color: #787878;
}

.eachSearchValueDesc {
  font-size: 2.75vw;
  color: #787878;
}

.eachSearchValueContentContainer {
  position: relative;
  display: inline-block;
  width: 80vw;
  height: 30vh;
}

.eachSearchValueContentContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.eachSearchValueContentContainer:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.eachSearchValueContentContainer img {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.eachSearchValueName {
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 3.5vw;
}

.fullScreenNavbarMobileMenus {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}

.navbarMobileMenuMainContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.navbarMobileMenuContainer {
  align-self: flex-end;
  width: 100%;
  height: 100vh;
  background-color: #d9d9d9;
}

.navbarMobileMenuContainer .closeBtn {
  margin-top: 1.25em;
  margin-right: 1em;
}

.navbarMobileMenuContainer .closeBtn img {
  width: 15px;
  height: auto;
}

.navbarMobileMenuSectionContainer {
  margin: 1em 1em 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.eachNavbarMobileMenu {
  margin-top: 1em;
  font-size: 16px;
}

@media only screen and (min-width: 640px) {
  .searchIcon {
    margin-top: 1vh;
    width: 15px;
    height: auto;
  }

  .navbarContainer {
    width: 100vw;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 11;
    border-bottom: 1px solid #d9d9d9;
  }

  .languageSection {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .languageSection img {
    width: 12.5px;
    height: auto;
  }

  .arrowContainer {
    margin-top: 0;
  }

  .currencyDropdown {
    padding-right: 10px;
    font-size: 17px;
  }

  .promoSection {
    background-color: #19b0df;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .promoValue {
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 21px;
    color: #ffffff;
    display: flex;
    gap: 15vw;
    width: 100%;
    transform: translateX(100%);
    animation: move 30s linear infinite;
  }

  .navbarSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logoSection {
    padding-top: 0;
    margin-left: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .otherSections {
    margin-right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .otherSectionsImgContainer {
    display: flex;
    position: relative;
  }

  .otherSectionsImgContainerAccount {
    display: flex;
    position: relative;
  }

  .totalUsersCartContainer {
    background-color: #fa3e3e;
    border-radius: 50%;
    color: #ffffff;
    min-width: 20px;
    height: 2.6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75vw;
    position: absolute;
    top: -1.25vh;
    right: -0.5vw;
  }

  .swingClubLogoNavbar {
    margin-top: -1.5vh;
    width: 1.85vw;
    height: auto;
  }

  .swingClubLabelNavbar {
    margin-left: 1vw;
    width: 12vw;
    height: auto;
  }

  .searchIcon {
    margin-top: 0;
    width: 1.75vw;
    height: auto;
  }

  .cartIcon {
    margin-left: 2vw;
    width: 1.75vw;
    height: auto;
    cursor: pointer;
  }

  .accountIcon {
    margin-left: 2.25vw;
    width: 1.4vw;
    height: auto;
    cursor: pointer;
  }

  .navbarItemsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 55vw;
  }

  .navbarItem {
    font-style: normal;
    font-size: 1.75vh;
    width: auto;
    height: 100%;
    text-align: center;
    color: #000000;
    margin-right: 2vw;
    margin-left: 2vw;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .subMenuNavbarContainer {
    border-bottom: 1px solid #d9d9d9;
    height: 50vh;
  }

  .subMenuContentContainer {
    display: flex;
    text-align: left;
    padding: 2.5vh 5vw 2.5vh 5vw;
    gap: 1vw;
  }

  .eachSubMenuContainer {
    width: 22.5vw;
  }

  .eachSubMenuContainer img {
    width: 22.5vw;
    height: 20vw;
    object-fit: cover;
  }

  .eachSubMenuContainer div {
    margin-top: 1vh;
    cursor: pointer;
    font-size: 1.1vw;
    :hover {
      font-weight: 600;
    }
  }

  .navbarSearchInputMainContainer {
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .searchOrderContainer {
    width: 90vw;
    display: flex;
    align-items: center;
  }

  .navbarSearchIcon {
    position: fixed;
    left: 6vw;
    width: 1.5vw;
    height: auto;
    z-index: 2;
  }

  .navbarSearchInputClearAndCloseBtn {
    position: fixed;
    z-index: 5;
    right: 6.5vw;
    z-index: 100;
    display: flex;
    align-items: center;
  }

  .navbarSearchInputClearAndCloseBtn .closeBtn img {
    width: 1.2vw;
    height: auto;
  }

  .clearBtnStyle {
    margin-right: 0;
    font-size: 1.2vw;
    cursor: pointer;
  }

  .navbarSearchInputContainer {
    display: flex;
    position: sticky !important;
    top: 0vh !important;
    z-index: 10;
  }

  .navbarSearchValueMainContainer {
    width: 90vw;
    position: sticky !important;
    top: 8vh !important;
    height: 80vh;
    overflow: auto;
    border-radius: 10px;
  }

  .navbarSearchValueContainer {
    background-color: #ffffff;
  }

  .navbarSearchValueProductMainContainer {
    padding-bottom: 30px;
    position: sticky !important;
    top: 7vh !important;
    z-index: 9;
  }

  .navbarSearchValueContentContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(27.5vw, 27.5vw));
    gap: 1vw;
  }

  .eachSearchValueContainer {
    margin: 2vh 1vw 2vh 1vw;
  }

  .eachSearchValueContainer img {
    display: block;
    width: 100%;
    height: auto;
  }

  .navbarSearchValueLabel {
    padding: 2vh 2vw 0vh 2vw;
    font-size: 1.75vw;
    color: #787878;
  }

  .eachSearchValueDesc {
    font-size: 1.15vw;
    color: #787878;
  }

  .eachSearchValueContentContainer {
    position: relative;
    display: inline-block;
    width: 25vw;
    height: 32.5vh;
  }

  .eachSearchValueContentContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
  }

  .eachSearchValueContentContainer:hover::before {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .eachSearchValueContentContainer img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .eachSearchValueName {
    margin-top: 0.75vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5vw;
  }

  .emptyNavbarSearchValueMainContainer {
    width: 90vw;
    position: sticky !important;
    top: 8vh !important;
    height: 80vh;
    overflow: auto;
  }

  .emptyNavbarSearchValueContainer {
    background-color: #ffffff;
    border-radius: 10px;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    color: #006787;
  }
}
