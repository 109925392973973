.myOrderDetailContentContainerPdf {
  width: 100vw;
}

.myOrderDetailMainContainerPdf {
  margin: 7.5vh 10vw 7vh 10vw;
}

.logoSectionPdf {
  padding-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swingClubLabelPdf {
  margin-left: 1vw;
  width: 16vw;
  height: auto;
}

.swingClubLogoPdf {
  margin-top: -1em;
  width: 2.5vw;
  height: auto;
}

.myOrderHeaderContainerPdf {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.eachMyOrderIdAndDateContainerPdf {
  margin-bottom: 3vh;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.eachMyOrderIdPdf {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.eachMyOrderDatePdf {
  margin-left: 1vw;
  color: #000;
  font-size: 20px;
  font-style: normal;
}

.eachMyOrderStatusMainContainerPdf {
  display: flex;
  align-items: center;
}

.eachMyOrderStatusContainerPdf {
  border-radius: 5px;
  background: #e8e8e8;
}

.eachMyOrderStatusContainerPdf div {
  margin: 0vh 1vw 2.5vh 1vw;
  font-size: 20px;
}

.myOrderUserDetailContainerPdf {
  text-align: left;
  margin-left: 0;
  margin-top: 0;
}

.eachMyOrderIdValuePdf {
  font-size: 18px;
}

.dividerMyOrderDetailPdf {
  border: 2px solid #d9d9d9;
  margin-top: 3vh;
}

.myOrderMainContentContainerPdf {
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.myOrderDetailBodyContainerPdf {
  min-height: 50vh;
}

.allItemsContainerPdf {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.eachOrderItemDetailContainerPdf {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vh;
}

.eachOrderItemDetailContentContainerPdf {
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eachMyOrderImgAndDescContainerPdf {
  display: flex;
}

.eachMyOrderImgAndDescContainerPdf img {
  border-radius: 5.677px;
  border: 0.284px solid #e8e8e8;
  width: 10vw;
  height: auto;
}

.eachMyOrderDescContainerPdf {
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}

.eachMyOrderCoverItemNamePdf {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.eachMyOrderTotalAmountContainerPdf {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #000;
  font-size: 20px;
  font-style: normal;
}

.myOrderDetailFooterContainerPdf {
  margin-top: 2.5vh;
  margin-left: 2vw;
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.myOrderDetailTotalItemStylePdf {
  color: #525252;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}

.myOrderDetailDeliveryFeeAndTotalAmountMainContainerPdf {
  width: 35vw;
}

.myOrderDetailDeliveryFeeAndTotalAmountContainerPdf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  color: #000;
  font-size: 20px;
  font-style: normal;
}

.myOrderDetailTotalAmountContainerPdf {
  font-weight: 700;
}
