.App {
  font-family: "Poppins", sans-serif;
  text-align: center;
  flex: 1;
  width: 100%;
}

.image-background {
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 25px;
}

.image-background::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  border-radius: 25px;
}

.image-background:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.image-background img {
  display: block;
  height: 100%;
  width: 100%;
}

.image-background-image {
  display: block;
  width: 65vw;
  height: 40vh;
}

#myInputText {
  font-family: "Poppins", sans-serif;
}

.errorMessage {
  margin-top: 0.5vh;
  font-size: 2.5vw;
  color: rgb(241, 41, 41);
}

.line-through {
  text-decoration: line-through 2px;
}

.loading-btn {
  background-image: -webkit-linear-gradient(
    left,
    rgb(203 213 225) 0px,
    #ffffff 110px,
    rgb(203 213 225) 110px
  );
  background-image: -o-linear-gradient(
    left,
    rgb(203 213 225) 0px,
    #ffffff 110px,
    rgb(203 213 225) 110px
  );
  background-image: linear-gradient(
    90deg,
    rgb(203 213 225) 0px,
    #ffffff 110px,
    rgb(203 213 225) 110px
  );
  background-size: 100vw;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
  animation: shine-loading-container-items 2s infinite ease-out;
}

@-webkit-keyframes shine-loading-container-items {
  0% {
    background-position: -140px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-loading-container-items {
  0% {
    background-position: -7.5vw;
  }
  40%,
  100% {
    background-position: 55vw;
  }
}

@media only screen and (min-width: 640px) {
  .image-background {
    width: 100%;
    position: relative;
    display: inline-block;
    border-radius: 25px;
  }

  .image-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 17.5vw;
    height: auto;
    aspect-ratio: 1/1;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    border-radius: 25px;
  }

  .image-background:hover::before {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    width: 17.5vw;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 25px;
  }

  .image-background img {
    display: block;
    width: 17.5vw;
    height: auto;
    aspect-ratio: 1/1;
  }

  .image-background-image {
    display: block;
    width: 17.5vw;
    height: 40vh;
  }

  .errorMessage {
    margin-top: 0.5vh;
    font-size: 0.7vw;
    color: rgb(241, 41, 41);
  }
}
