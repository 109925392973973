.image-gallery-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.image-gallery {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 16px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.image-gallery::-webkit-scrollbar {
  width: 0.5rem;
}

.image-gallery::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.image-gallery::-webkit-scrollbar-track {
  background-color: transparent;
}

.gallery-item {
  flex: 0 0 auto;
  width: 150px;
  cursor: pointer;
}

.eachItemDescMobile {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
  text-align: left;
}

.each-galery-item {
  width: 100%;
}

.scroll-button {
  border: none;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
  color: #d9d9d9;
}

.left {
  margin-right: 0px;
}

.right {
  margin-left: 0px;
}

.headerAndScrollBtn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.headerAndScrollBtn div {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.galeryItemFlex {
  margin: 0.5em 0.5em 0.5em 0.5em;
  display: flex;
  gap: 0.5em;
}

.galeryItemFlex img {
  margin-top: 0.25em;
}

.sizeTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.sizeEquivalentToSizeContainer {
  display: flex;
  margin-top: 0.35em;
  gap: 0.2em;
  font-style: normal;
  font-size: 12px;
}

.sizeConversionsValueContainer {
  margin-top: 1em;
  margin-bottom: 1em;
}

.eachSizeConversionContainer {
  margin-top: 0.5em;
  display: flex;
  gap: 2em;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.headerAndScrollBtnlandingPageFeaturedPordutsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 3vh;
}

@media only screen and (min-width: 640px) {
  .image-gallery-container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .image-gallery {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    gap: 1vw;
  }

  .image-gallery::-webkit-scrollbar {
    width: 0.5rem;
  }

  .image-gallery::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .image-gallery::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .gallery-item {
    flex: 0 0 auto;
    width: 17.5vw;
    cursor: pointer;
  }

  .each-galery-item {
    width: 100%;
  }

  .scroll-button {
    border: none;
    background: transparent;
    font-size: 30px;
    cursor: pointer;
    color: #d9d9d9;
  }

  .left {
    margin-right: 0px;
  }

  .right {
    margin-left: 0px;
  }

  .headerAndScrollBtn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  .headerAndScrollBtn div {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }

  .headerAndScrollBtnlandingPageFeaturedPordutsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2em;
  }

  .galeryItemFlex {
    margin: 1em 1em 1em 1em;
    display: flex;
    gap: 0.5em;
  }

  .galeryItemFlex img {
    margin-top: 0.35em;
  }

  .sizeTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }

  .sizeEquivalentToSizeContainer {
    display: flex;
    margin-top: 0.35em;
    gap: 0.2em;
    font-style: normal;
    font-size: 18px;
  }

  .sizeConversionsValueContainer {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .eachSizeConversionContainer {
    margin-top: 0.5em;
    display: flex;
    gap: 2em;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
}
